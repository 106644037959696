// src/lib/api/client.js
import axios from 'axios';

// API 서버 URL을 baseURL로 설정
const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || 'http://ceo-server.rctown.co.kr' || 'http://116.124.133.159:3034', // 실제 서버 URL로 설정
  timeout: 120000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default client;
