/* eslint-disable no-useless-escape */


import { useEffect } from "react";

export default function CopyrightContainer() {
  useEffect(() => {
    // eslint-disable-next-line no-useless-escape
    console.info(
      `%c
                      ______    __      __            __                 
                     /      \  /  |    /  |          /  |                
  _______   ______  /$$$$$$  |_$$ |_   $$ |  ______  $$ |____    _______ 
 /       | /      \ $$ |_ $$// $$   |  $$ | /      \ $$      \  /       |
/$$$$$$$/ /$$$$$$  |$$   |   $$$$$$/   $$ | $$$$$$  |$$$$$$$  |/$$$$$$$/ 
$$      \ $$ |  $$ |$$$$/      $$ | __ $$ | /    $$ |$$ |  $$ |$$      \ 
 $$$$$$  |$$ \__$$ |$$ |       $$ |/  |$$ |/$$$$$$$ |$$ |__$$ | $$$$$$  |
/     $$/ $$    $$/ $$ |       $$  $$/ $$ |$$    $$ |$$    $$/ /     $$/ 
$$$$$$$/   $$$$$$/  $$/         $$$$/  $$/  $$$$$$$/ $$$$$$$/  $$$$$$$/  
                                                                         
                                                                         
                                                                         
`,
      "color:red"
    );
    console.info("이 웹사이트는 알씨타운 (소프트랩스)에 의해 제작되었습니다.");
    console.info("홈페이지: https://www.rctown.co.kr");
    console.info("연락처: info@rctown.co.kr");
  }, []);

  return null; // 컴포넌트가 아무것도 렌더링하지 않도록 null 반환
}
