// src/lib/store/useStore.js
import { create } from 'zustand'; // 명시적 내보내기 사용

const useStore = create((set) => ({
  com_name: 'SK', // 기본값을 'SK'로 설정
  setCom_Name: (name) => {
    set({ com_name: name || 'SK' });
  },

  uuid: '', 
  setUuid: (uuid) => {
    set({ uuid: uuid });
  },

  clientIP: '', 
  setClientIP: (clientIP) => {
    set({ clientIP: clientIP });
  },

  comp_com: '',
  setComp_Com: (com) => set({ comp_com: com }),

  selectDate: new Date(), // 날짜 상태 (오늘 날짜 기본값)
  setSelectDate: (date) => set({ selectDate: date }), // 날짜 업데이트 함수

  dateRange : 'daily',
  setDateRange: (range) => set({ dateRange: range }),
}));

export default useStore;
