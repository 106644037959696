import React, { useEffect, useState } from 'react';
import useStore from '../lib/store/useStore'; // zustand 스토어 임포트
import { getAvg, nowRiskScore } from '../service/risk.service';

const CeoScoreBar = () => {
  const [score, setScore] = useState(null);
  const [avg, setAvg] = useState(null);
  const [loading, setLoading] = useState(true);

  const { com_name, selectDate } = useStore(state => ({
    com_name: state.com_name,
    selectDate: state.selectDate,
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 점수 가져오기
        const fetchedScore = await nowRiskScore(com_name, selectDate);
        console.log('fetchedScore 값:', fetchedScore);

        const scoreValue = typeof fetchedScore === 'number'
          ? fetchedScore
          : parseFloat(fetchedScore); // 문자열이면 숫자로 변환

        setScore(scoreValue); // 소수점 두 번째 자리까지 설정
        console.log('ceo점수:', scoreValue);

        // 평균값 가져오기
        const fetchedAvg = await getAvg(com_name, selectDate);
        setAvg(fetchedAvg); // 평균값도 소수점 두 번째 자리까지 설정
        console.log('평균값:', fetchedAvg);
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [com_name, selectDate]);

  if (loading) {
    return <div>Loading...</div>;
  } 

  return (
    <div className="bg-gray-800 text-gray-100 p-4 rounded-md shadow-sm h-10 w-full max-w-80">
      <div className="flex justify-center items-center h-full text-sm">
        <div className="flex items-center whitespace-nowrap ">
          <span className="font-semibold mr-2 text-sm custom:text-base ">Score : </span>
          <span className='font-semibold mr-8  text-green-600 text-sm custom:text-base'>{score}</span>
        </div>
        <div className="flex items-center whitespace-nowrap">
          <span className="font-semibold mr-2 text-sm custom:text-base">Score Avg : </span>
          <span className='font-semibold text-green-600 text-sm custom:text-base'>{avg}</span>
        </div>
      </div>
    </div>
  );
};

export default CeoScoreBar;
