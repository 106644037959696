import React from 'react';

const CompactVisitorStats = ({ dailyVisitors, weeklyVisitors, monthlyVisitors }) => {
  return (
    <div className="bg-gray-800 text-gray-100 p-4 rounded-md shadow-sm h-10 w-full max-w-4xl">
      <div className="flex justify-between items-center h-full text-sm">
        <div className="flex items-center whitespace-nowrap">
          <span className="font-medium mr-2">일일:</span>
          <span>{dailyVisitors}</span>
        </div>
        <div className="flex items-center whitespace-nowrap">
          <span className="font-medium mr-2">주간:</span>
          <span>{weeklyVisitors}</span>
        </div>
        <div className="flex items-center whitespace-nowrap">
          <span className="font-medium mr-2">월간:</span>
          <span>{monthlyVisitors}</span>
        </div>
      </div>
    </div>
  );
};

export default CompactVisitorStats;
