import React, { useState, useEffect } from 'react';  
import { getSummaryInfo } from '../service/risk.service';  
import useStore from '../lib/store/useStore'; 

function CeoSummary() {
    const [filteredSummaries, setFilteredSummaries] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);  

    const { com_name, selectDate } = useStore(state => ({
        com_name: state.com_name,
        selectDate: state.selectDate,
    }));

    useEffect(() => {
        const fetchSummaries = async () => {
            try {
                console.log('API 호출 시작');
                const summaries = await getSummaryInfo(com_name, selectDate);
                console.log('API 응답 데이터:', summaries);
    
                // 응답이 배열인지 확인 후 처리
                if (Array.isArray(summaries)) {
                    const validSummaries = summaries
                        .filter(item => item && item.summary && item.summary.trim() !== '')
                        .map(item => item.summary);
    
                    console.log('유효한 요약:', validSummaries);
                    
                    setFilteredSummaries(validSummaries);
    
                    if (validSummaries.length > 0) {
                        const intervalId = setInterval(() => {
                            setCurrentIndex((prevIndex) => (prevIndex + 1) % validSummaries.length);
                        }, 5000);
                        return () => clearInterval(intervalId);
                    }
                } else {
                    console.log('요약된 기사가 없습니다.');
                    setFilteredSummaries([]);  // 배열이 아닌 경우 빈 배열 설정
                }
    
            } catch (error) {
                console.error('API 호출 실패:', error);
                setFilteredSummaries([]);  // 에러 발생 시에도 빈 배열 설정
            } finally {
                // 무조건 로딩을 false로 설정
                setLoading(false);
            }
        };
    
        if (com_name && selectDate) {
            fetchSummaries();
        } else {
            console.log('com_name 또는 selectDate가 유효하지 않습니다');
            setLoading(false);
        }
    }, [com_name, selectDate]);
    

    return (
        <div>
            <p className='mx-auto text-base font-medium pt-2 pl-2'>Summarize article</p>  
            <div className="text-xl font-medium text-green-500 pl-8 pb-2">
                <div className="truncate pt-2">
                    {loading ? 'Loading...' : 
                     filteredSummaries.length > 0 ? filteredSummaries[currentIndex] : '조회된 요약 기사가 없습니다'}
                </div>
            </div>        
        </div>
    );
}

export default CeoSummary;
