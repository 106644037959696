import React, { useEffect, useState } from 'react';
import { getCmpArticleList } from '../service/risk.service'; // 서비스 함수 임포트
import useStore from '../lib/store/useStore'; // Zustand 스토어 임포트

const CeoCmpArticle = () => {
  const { com_name, selectDate } = useStore(state => ({
    com_name: state.com_name,
    selectDate: state.selectDate,
  }));

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [noArticles, setNoArticles] = useState(false); // 새로운 상태 추가

  const fetchData = async () => {
    try {
      const response = await getCmpArticleList(com_name, selectDate);

      if (response?.Message === 'Article Data Not Found') {
        setData([]);
        setNoArticles(true); // 기사가 없다는 상태로 설정
      } else {
        setData(Array.isArray(response) ? response : [response]);
        setNoArticles(false); // 기사가 있다는 상태로 설정
      }
      setError(null); // 에러 상태를 null로 설정
    } catch (error) {
      setError(error);
      setNoArticles(false); // 에러 발생 시 기사가 없다는 상태를 설정하지 않음
      console.log('데이터 패칭 오류:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [com_name, selectDate]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <p className='mx-auto text-base font-medium pt-2 p-3'>유사 기업 사례</p>
      <div className="max-h-80 h-60 overflow-y-scroll border border-gray-700 rounded-lg">
      <table className="min-w-full bg-gray-800 border border-gray-700 border-t-0 border-b-0">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-700 text-left w-1/8 whitespace-nowrap">원본기사날짜</th>
            <th className="py-2 px-4 border-b border-gray-700 text-left w-1/8 hidden sm:table-cell">비교회사</th>
            <th className="py-2 px-4 border-b border-gray-700 text-left w-1/8 hidden md:table-cell whitespace-nowrap">비교기사날짜</th>
            <th className="py-2 px-4 border-b border-gray-700 text-left w-3/8">기사제목</th>
            <th className="py-2 px-4 border-b border-gray-700 text-left w-2/8 hidden lg:table-cell">기사내용</th>
          </tr>
        </thead>
        <tbody>
          {noArticles ? (
            <tr>
              <td colSpan="5" className="py-2 px-4 border-b border-gray-700 text-center">
                조회된 기사가 없습니다.
              </td>
            </tr>
          ) : (
            data.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b border-gray-700 text-[10px] sm:text-xs md:text-sm lg:text-base whitespace-nowrap">
                  {item.date || ''}
                </td>
                <td className="py-2 px-4 border-b border-gray-700 hidden sm:table-cell text-[10px] sm:text-xs md:text-sm lg:text-base whitespace-nowrap">
                  {item.comparison_company_name || ''}
                </td>
                <td className="py-2 px-4 border-b border-gray-700 hidden md:table-cell text-[10px] sm:text-xs md:text-sm lg:text-base whitespace-nowrap">
                  {item.comparison_date || ''}
                </td>
                <td className="py-2 px-4 border-b border-gray-700 truncate max-w-xs text-[10px] sm:text-xs md:text-sm lg:text-base">
                  <a 
                    href={item.article_link || '#'}
                    className="text-blue-500 hover:text-blue-700 truncate" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {item.article_title || ''}
                  </a>
                </td>
                <td className="py-2 px-4 border-b border-gray-700 truncate max-w-xs text-[10px] sm:text-xs md:text-sm lg:text-base">
                  {item.article_contents || ''}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      </div>
    </>
  );
};

export default CeoCmpArticle;
