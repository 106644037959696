import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../custom-datepicker.css';
import { ko } from 'date-fns/locale';
import CeoCmpArticle from './CeoCmpArticle';
import CeoArticle from './CeoArticle';
import CeoRiskChart from './CeoRiskChart';
import CompanySelector from './CompanySelector';
import useStore from '../lib/store/useStore';
import CeoRiskRank from './CeoRiskRank';
import DropdownMenu from './DropdownMenu';
import CompactVisitorStats from './CompactVisitorStats';
import FeedbackModal from './FeedbackModal';
import KeywordCloud from './KeywordCloud';

const Box = ({ className = '', children }) => (
  <div className={`bg-gray-800 rounded-md border border-gray-700 ${className}`}>
    {children}
  </div>
);

const DashBoard2 = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { selectDate, setSelectDate, dateRange, setDateRange } = useStore((state) => ({
    selectDate: state.selectDate,
    setSelectDate: state.setSelectDate,
    dateRange: state.dateRange,
    setDateRange: state.setDateRange,
  }));

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // 방문자수 임의값 설정
  const dailyVisitors = 120;
  const weeklyVisitors = 850;
  const monthlyVisitors = 3200;

  const handlePeriodChange = (newPeriod) => {
    setDateRange(newPeriod);  // Zustand store에 새로운 범위 설정
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 p-4">
      <div className="grid grid-cols-12 gap-1.5">
        
        <div className="col-span-5 flex flex-col">
          <div className="h-10 w-full flex items-center justify-left px-2">
            <CompanySelector className="col-span-8 mb-4 col" />&nbsp;
            <span className="mr-0 border border-gray-600 rounded-md px-2 py-1 text-blue-500 whitespace-nowrap shadow-lg bg-gradient-to-r from-gray-700 to-gray-900">날짜</span>
            <div className="relative w-40">
              <DatePicker
                selected={selectDate}
                onChange={(date) => setSelectDate(date)}
                dateFormat="yyyy년 MM월 dd일"
                className="bg-gray-800 text-gray-100 border border-gray-600 rounded-md px-2 py-1 outline-none focus:border-blue-500 w-full text-center pr-6"
                calendarClassName="custom-datepicker"
                locale={ko}
              />
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              </div>
            </div>
            {/* 일간, 주간, 월간 버튼 추가 */}
            <div className="flex space-x-2 ml-4">
              <button
                onClick={() => handlePeriodChange('daily')}
                className={`px-3 py-1 rounded-md border whitespace-nowrap ${dateRange === 'daily' ? 'text-blue-500 border-blue-500' : 'text-gray-100 border-gray-600'} bg-gradient-to-r from-gray-700 to-gray-900`}
              >
                일d간
              </button>
              <button
                onClick={() => handlePeriodChange('weekly')}
                className={`px-3 py-1 rounded-md border whitespace-nowrap ${dateRange === 'weekly' ? 'text-blue-500 border-blue-500' : 'text-gray-100 border-gray-600'} bg-gradient-to-r from-gray-700 to-gray-900`}
              >
                주간
              </button>
              <button
                onClick={() => handlePeriodChange('monthly')}
                className={`px-3 py-1 rounded-md border whitespace-nowrap ${dateRange === 'monthly' ? 'text-blue-500 border-blue-500' : 'text-gray-100 border-gray-600'} bg-gradient-to-r from-gray-700 to-gray-900`}
              >
                월간
              </button>
            </div>
            &nbsp;&nbsp;<DropdownMenu className="flex-grow" />
          </div>  
        </div>

        <div className="col-span-4"></div>

        <div className="col-span-2 grid grid-cols-1 gap-2 items-center">
          <CompactVisitorStats
            dailyVisitors={dailyVisitors}
            weeklyVisitors={weeklyVisitors}
            monthlyVisitors={monthlyVisitors}
          />
        </div>

        <div className="w-full flex">
          <button
            onClick={openModal}
            className="bg-gray-800 text-gray-100 border border-gray-600 rounded-md px-3 py-1 shadow-lg bg-gradient-to-r from-gray-700 to-gray-900 whitespace-nowrap hover:text-blue-500"
          >
            피드백
          </button>
          <FeedbackModal isOpen={isModalOpen} onRequestClose={closeModal} />
        </div>

        <div className="col-span-12 grid grid-cols-8 gap-1.5">
          <Box className="col-span-5 row-span-2 h-auto w-full">
            <CeoRiskChart />
          </Box>
          <Box className='col-span-3 row-span-2 h-full '>
          </Box>
          <Box className='col-span-8 h-20' >
          </Box> 
          <Box className="col-span-4 row-span-2 h-auto">
            <CeoCmpArticle />
          </Box>
          <Box className="col-span-2 row-span-3 h-auto">
          </Box>
          <Box className="col-span-2 row-span-3 h-auto">
          </Box>
          <Box className="col-span-4 h-auto">
            <CeoArticle />
          </Box>
        </div>
       
      </div>
    </div>
  );
};

export default DashBoard2;
