import React, { useState, useEffect } from 'react'; 
import { getRank } from '../service/risk.service'; // 서비스 함수 임포트
import useStore from '../lib/store/useStore'; // Zustand 스토어 임포트

function CeoRiskRank() {

  const { com_name } = useStore(state => ({
    com_name: state.com_name,
  }));
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await getRank();
        console.log("Fetched Data:", fetchedData); // 가져온 데이터 로그

        if (Array.isArray(fetchedData)) {
          // 선택된 회사의 데이터를 먼저 필터링
          const selectedCompanyData = fetchedData.filter(item => item.company_name === com_name);
          console.log(`Filtered Data for ${com_name}:`, selectedCompanyData); // 선택된 회사 데이터 로그

          // 선택된 회사를 제외한 나머지 데이터 필터링
          const otherCompaniesData = fetchedData.filter(item => item.company_name !== com_name);
          console.log(`Remaining Data excluding ${com_name}:`, otherCompaniesData); // 나머지 데이터 로그

          // 선택된 회사를 먼저 포함하고 나머지 데이터를 추가한 후 정렬
          let combinedData = [...selectedCompanyData, ...otherCompaniesData];
          console.log("Combined Data before sorting:", combinedData); // 병합된 데이터 로그

          // 순위(rank)에 따라 오름차순으로 정렬
          combinedData.sort((a, b) => a.rank - b.rank);
          console.log("Sorted Combined Data:", combinedData); // 정렬된 데이터 로그

          // 상위 5개 항목을 선택, 선택된 회사가 포함되도록
          const topFiveData = combinedData.slice(0, 5);

          // 선택된 회사가 Top 5에 포함되도록 보장
          if (selectedCompanyData.length > 0 && !topFiveData.some(item => item.company_name === com_name)) {
            topFiveData.pop(); // 가장 낮은 순위 항목 제거
            topFiveData.push(selectedCompanyData[0]); // 선택된 회사 추가
            topFiveData.sort((a, b) => a.rank - b.rank); // 다시 정렬
          }

          console.log("Final Top 5 Data:", topFiveData); // 최종 Top 5 로그

          setData(topFiveData);
        } else {
          console.error('Fetched data is not an array:', fetchedData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [com_name]);

  return (
    <>
      <p className='mx-auto text-base font-medium pt-2 p-3'>Owner Risk Top 5 Rank</p>
      <table className="min-w-full bg-gray-800 border border-gray-700 border-t-0 border-b-0 text-base">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-700 text-center whitespace-nowrap text-base w-1/5">
              순위
            </th>
            <th className="py-2 px-4 border-b border-gray-700 text-center whitespace-nowrap text-base w-2/ ">
              이름
            </th>
            <th className="py-2 px-4 border-b border-gray-700 text-center whitespace-nowrap text-base w-15">
              AI Score
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className={item.company_name === com_name ? 'text-yellow-400' : ''}>
              <td className="py-2 px-4 border-b border-gray-700 text-center text-base ">
                {item.rank ? item.rank : ''}
              </td>
              <td className="py-2 px-4 border-b border-gray-700 text-center text-base  truncate max-w-[4ch]">
                {item.company_name || ''}
              </td>
              <td className="py-2 px-4 border-b border-gray-700 text-center text-base ">
                {item.ai_score ? item.ai_score.toFixed(2) : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default CeoRiskRank;
