import React from 'react';
import DashBoard from './components/DashBoard';
import CopyrightContainer from './pages/CopyrightContainer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <CopyrightContainer />
        <Routes>
          {/* 메인 페이지로 DashBoard 설정 */}
          <Route path="/" element={<DashBoard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
