import React, { useState, useEffect } from 'react';
import { getRank } from '../service/risk.service'; // 서비스 함수 임포트
import useStore from '../lib/store/useStore'; // Zustand 스토어 임포트
import axios from 'axios'; // axios 임포트

function CeoRiskRank() {
  const { com_name, selectDate, dateRange } = useStore(state => ({
    com_name: state.com_name,
    selectDate: state.selectDate,
    dateRange: state.dateRange,
  }));

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  useEffect(() => {
    const source = axios.CancelToken.source(); // cancelToken 생성

    const fetchData = async () => {
      try {
        setLoading(true); // 로딩 시작
        const fetchedData = await getRank(selectDate, dateRange, source.token);
        console.log("Fetched Data:", fetchedData); // 가져온 데이터 로그

        if (Array.isArray(fetchedData)) {
          const selectedCompanyData = fetchedData.filter(item => item.company_name === com_name);
          const otherCompaniesData = fetchedData.filter(item => item.company_name !== com_name);

          let combinedData = [...selectedCompanyData, ...otherCompaniesData];
          combinedData.sort((a, b) => a.rank - b.rank);

          const topFiveData = combinedData.slice(0, 5);

          // 선택된 회사가 포함되지 않은 경우 보장
          if (selectedCompanyData.length > 0 && !topFiveData.some(item => item.company_name === com_name)) {
            topFiveData.pop();
            topFiveData.push(selectedCompanyData[0]);
            topFiveData.sort((a, b) => a.rank - b.rank);
          }

          console.log("Final Top 5 Data:", topFiveData);
          setData(topFiveData);
        } else {
          console.error('Fetched data is not an array:', fetchedData);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('요청이 취소되었습니다.');
        } else {
          console.error('데이터 가져오는 중 오류 발생:', error);
        }
      } finally {
        setLoading(false); // 로딩 끝
      }
    };

    fetchData();

    // 컴포넌트가 언마운트되면 요청 취소
    return () => {
      source.cancel('컴포넌트가 언마운트되어 요청이 취소되었습니다.');
    };
  }, [com_name, selectDate, dateRange]);

  return (
    <>
      <p className='mx-auto text-base font-medium pt-2 p-3'>Owner Risk Top 5 Rank</p>
      <table className="min-w-full bg-gray-800 border border-gray-700 border-t-0 border-b-0 text-base h-60">
        <thead>
          <tr>
            <th className="py-6 px-4 border-b border-gray-700 text-center whitespace-nowrap text-base w-1/2">
              이름
            </th>
            <th className="py-6 px-4 border-b border-gray-700 text-center whitespace-nowrap text-base w-1/2">
              AI Score
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="2" className="text-center py-4">Loading...</td>
            </tr>
          ) : (
            data.map((item, index) => (
              <tr key={index} className={item.company_name === com_name ? 'text-yellow-400' : ''}>
                <td className={`py-4 px-4 text-center text-base truncate max-w-[4ch] ${index === data.length - 1 ? '' : 'border-b border-gray-700'}`}>
                  {item.company_name || ''}
                </td>
                <td className={`py-4 px-4 text-center text-base ${index === data.length - 1 ? '' : 'border-b border-gray-700'}`}>
                  {item.ai_score ? item.ai_score : 0}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  );
}

export default CeoRiskRank;
