import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, Filler } from 'chart.js';
import useStore from '../lib/store/useStore'; // Zustand 스토어 임포트
import { getChartData } from '../service/risk.service'; // 서비스 함수 임포트
import CeoScoreBar from './CeoScoreBar';

// Chart.js의 모듈을 등록합니다.
ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, Filler);

const CeoRiskChart = () => {
  const { com_name, selectDate, dateRange } = useStore(state => ({
    com_name: state.com_name,
    selectDate: state.selectDate,
    dateRange: state.dateRange,
  }));

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [cmpCompany, setCmpCompany] = useState('');
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const [summaries, setSummaries] = useState([]); // 메인 회사의 요약 정보
  const [cmpSummaries, setCmpSummaries] = useState([]); // 비교 회사의 요약 정보

  const fetchData = async () => {
    try {
      setLoading(true); // 데이터 로드 시작 시 로딩 상태 설정

      // 메인 회사 데이터 불러오기
      const response = await getChartData(com_name, selectDate, dateRange);
      if (!response || response.length === 0) {
        throw new Error('No data available for the selected company and date.');
      }

      const sortedResponse = response.sort((a, b) => new Date(a.date) - new Date(b.date));
      const labels = generateLabels(sortedResponse, selectDate, dateRange);

      const data = labels.map(label => {
        const item = sortedResponse.find(item => {
          if (dateRange === 'daily') {
            const itemTime = item.date.split(' ')[1];
            return itemTime === label;
          } else {
            return item.date === label;
          }
        });
        return item && item.score != null ? parseFloat(item.score.toFixed(2)) : null;
      });

      // 메인 회사의 요약 데이터 저장
      const summariesData = labels.map(label => {
        const item = sortedResponse.find(item => {
          return item.date.includes(label.split(' ')[0]);
        });
        return item ? item.summary : 'No summary available';
      });
      setSummaries(summariesData);

      let cmpData = [];
      let cmpSummariesData = [];

      if (cmpCompany) {
        const cmpResponse = await getChartData(cmpCompany, selectDate, dateRange);
        if (cmpResponse && cmpResponse.length > 0) {
          const sortedCmpResponse = cmpResponse.sort((a, b) => new Date(a.date) - new Date(b.date));

          cmpData = labels.map(label => {
            const item = sortedCmpResponse.find(item => {
              if (dateRange === 'daily') {
                const itemTime = item.date.split(' ')[1];
                return itemTime === label;
              } else {
                return item.date === label;
              }
            });
            return item && item.score != null ? parseFloat(item.score.toFixed(2)) : null;
          });

          // 비교 회사의 요약 데이터 저장
          cmpSummariesData = labels.map(label => {
            const item = sortedCmpResponse.find(item => {
              return item.date.includes(label.split(' ')[0]);
            });
            return item ? item.summary : 'No summary available';
          });
          setCmpSummaries(cmpSummariesData);
        }
      }

      setChartData({
        labels: labels,
        datasets: [
          {
            label: `${com_name} Sentimental Score`,
            data: data,
            borderColor: 'rgba(72, 187, 120, 1)',
            backgroundColor: 'rgba(55, 150, 100, 0.5)',
            fill: false,
            spanGaps: true,
            tension: 0.5,
          },
          cmpCompany && {
            label: `${cmpCompany} Sentimental Score`,
            data: cmpData,
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            fill: false,
            spanGaps: true,
            tension: 0.5,
          }
        ].filter(Boolean)
      });

    } catch (error) {
      console.log('데이터 패칭 오류:', error);
      setChartData({
        labels: [],
        datasets: []
      });
    } finally {
      setLoading(false); // 데이터 로드 완료 시 로딩 상태 해제
    }
  };

  useEffect(() => {
    fetchData();
  }, [com_name, selectDate, cmpCompany, dateRange]);

  const generateLabels = (response, date, range) => {
    if (range === 'daily') {
      return Array.from({ length: 24 }, (_, i) => `${String(i).padStart(2, '0')}:00`);
    } else {
      return response.map(item => item.date);
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
      y: {
        title: {
          display: true,
        },
        ticks: {
          stepSize: 20,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'center',
        display: true,
      },
      tooltip: {
        backgroundColor: '#000000',
        titleColor: '#ffffff',
        bodyColor: '#ffffff',
        borderColor: '#ffffff',
        borderWidth: 1,
        callbacks: {
          title: function (tooltipItems) {
            const label = tooltipItems[0].label;
            return dateRange === 'daily' ? `Time: ${label}` : `Date: ${label}`;
          },
          label: function (tooltipItem) {
            const datasetIndex = tooltipItem.datasetIndex; // 데이터 세트 인덱스
            const datasetLabel = chartData.datasets[datasetIndex].label; // 데이터 세트 라벨
            const score = `Score: ${tooltipItem.raw}`; // 점수 정보

            return `${datasetLabel}: ${score}`; // 툴팁 라벨 설정
          },
          afterLabel: function (tooltipItem) {
            const datasetIndex = tooltipItem.datasetIndex; // 데이터 세트 인덱스
            let summary = '';

            // 데이터 세트에 따라 요약 정보를 다르게 표시
            if (datasetIndex === 0) {
              summary = summaries[tooltipItem.dataIndex] || 'No summary available';
            } else if (datasetIndex === 1) {
              summary = cmpSummaries[tooltipItem.dataIndex] || 'No summary available';
            }

            // 요약 데이터 줄 바꿈 처리
            const maxLineLength = 70;
            let firstLine = summary.slice(0, maxLineLength);
            let secondLine = summary.length > maxLineLength ? summary.slice(maxLineLength, maxLineLength * 2) : '';

            if (secondLine.length > maxLineLength) {
              secondLine = secondLine.slice(0, maxLineLength - 3) + '...';
            } else if (secondLine) {
              secondLine += "'";
            }

            return ['', firstLine, secondLine].filter(Boolean);
          },
        },
      },
    },
  };

  return (
    <>
      <div className="flex items-center justify-between mx-auto pl-3 pt-1 pr-3">
        <p className='text-base font-medium'>Sentimental Score</p>
        <div className="flex flex-nowrap items-center space-x-4">
          <CeoScoreBar />
          <select
            className="bg-gray-800 text-gray-100 border border-gray-600 rounded-md px-2 py-1 outline-none focus:border-blue-500 w-auto text-center"
            value={cmpCompany}
            onChange={(e) => setCmpCompany(e.target.value)}
          >
            <option value="">선택</option>
            <option value="SK">SK</option>
            <option value="삼성전자">삼성전자</option>
            <option value="현대자동차">현대자동차</option>
            <option value="LG">LG</option>
            <option value="네이버">네이버</option>
            <option value="카카오">카카오</option>
            <option value="포스코">포스코</option>
            <option value="한진칼">한진칼</option>
            <option value="하이브">하이브</option>
            <option value="SPC">SPC</option>
          </select>
        </div>
      </div>
      <div className="relative w-full h-96 mx-auto">
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            <p className="ml-4 text-white">Loading...</p>
          </div>
        ) : (
          <Line data={chartData} options={{ ...options, maintainAspectRatio: false }} />
        )}
      </div>
    </>
  );
};

export default CeoRiskChart;