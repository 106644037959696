// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/custom-datepicker.css */

/* 커스텀 달력 스타일 */
.react-datepicker {
  background-color: #1f2937; /* 배경색을 주변 스타일과 맞추기 */
  color: #e5e7eb; /* 글자 색상 */
  border: 1px solid #374151; /* 테두리 색상 */
}

.react-datepicker__header {
  background-color: #374151; /* 헤더 배경색 */
  border-bottom: 1px solid #1f2937; /* 헤더 아래 테두리 */
}

.react-datepicker__day {
  color: #e5e7eb; /* 날짜 텍스트 색상 */
}

.react-datepicker__day:hover {
  background-color: #4b5563; /* 날짜 호버 배경색 */
}

.react-datepicker__day--selected {
  background-color: #3b82f6; /* 선택된 날짜 배경색 */
  color: #ffffff; /* 선택된 날짜 텍스트 색상 */
}

.react-datepicker__month-container {
  border-radius: 8px; /* 달력 컨테이너의 모서리 둥글기 */
}

/* 현재 월/연도 스타일 */
.react-datepicker__current-month {
  color: #ffffff; /* 월/연도 텍스트 색상을 흰색으로 변경 */
}

/* 월화수목 폰트색상도 미리 바꾸둬자 나중에 말나올수도있으니. */
.react-datepicker__day-name {
  color: #ffffff; /* 요일 텍스트 색상을 흰색으로 설정 */
}`, "",{"version":3,"sources":["webpack://./src/custom-datepicker.css"],"names":[],"mappings":"AAAA,qCAAqC;;AAErC,eAAe;AACf;EACE,yBAAyB,EAAE,qBAAqB;EAChD,cAAc,EAAE,UAAU;EAC1B,yBAAyB,EAAE,WAAW;AACxC;;AAEA;EACE,yBAAyB,EAAE,WAAW;EACtC,gCAAgC,EAAE,cAAc;AAClD;;AAEA;EACE,cAAc,EAAE,cAAc;AAChC;;AAEA;EACE,yBAAyB,EAAE,cAAc;AAC3C;;AAEA;EACE,yBAAyB,EAAE,eAAe;EAC1C,cAAc,EAAE,kBAAkB;AACpC;;AAEA;EACE,kBAAkB,EAAE,qBAAqB;AAC3C;;AAEA,gBAAgB;AAChB;EACE,cAAc,EAAE,yBAAyB;AAC3C;;AAEA,qCAAqC;AACrC;EACE,cAAc,EAAE,uBAAuB;AACzC","sourcesContent":["/* src/styles/custom-datepicker.css */\r\n\r\n/* 커스텀 달력 스타일 */\r\n.react-datepicker {\r\n  background-color: #1f2937; /* 배경색을 주변 스타일과 맞추기 */\r\n  color: #e5e7eb; /* 글자 색상 */\r\n  border: 1px solid #374151; /* 테두리 색상 */\r\n}\r\n\r\n.react-datepicker__header {\r\n  background-color: #374151; /* 헤더 배경색 */\r\n  border-bottom: 1px solid #1f2937; /* 헤더 아래 테두리 */\r\n}\r\n\r\n.react-datepicker__day {\r\n  color: #e5e7eb; /* 날짜 텍스트 색상 */\r\n}\r\n\r\n.react-datepicker__day:hover {\r\n  background-color: #4b5563; /* 날짜 호버 배경색 */\r\n}\r\n\r\n.react-datepicker__day--selected {\r\n  background-color: #3b82f6; /* 선택된 날짜 배경색 */\r\n  color: #ffffff; /* 선택된 날짜 텍스트 색상 */\r\n}\r\n\r\n.react-datepicker__month-container {\r\n  border-radius: 8px; /* 달력 컨테이너의 모서리 둥글기 */\r\n}\r\n\r\n/* 현재 월/연도 스타일 */\r\n.react-datepicker__current-month {\r\n  color: #ffffff; /* 월/연도 텍스트 색상을 흰색으로 변경 */\r\n}\r\n\r\n/* 월화수목 폰트색상도 미리 바꾸둬자 나중에 말나올수도있으니. */\r\n.react-datepicker__day-name {\r\n  color: #ffffff; /* 요일 텍스트 색상을 흰색으로 설정 */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
