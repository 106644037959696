import React, { useState } from 'react';
import Modal from 'react-modal';
import { sendFeedback } from '../service/risk.service'; // 피드백 보내기 함수 임포트

// 모달 스타일 정의
const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '500px',
    backgroundColor: '#1f2937', // Tailwind 'bg-gray-800'
    color: '#f9fafb', // Tailwind 'text-gray-100'
    padding: '20px',
    borderRadius: '8px',
    overflow: 'auto',
  },
};

const FeedbackModal = ({ isOpen, onRequestClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    organization: '',
    email: '',
    phone: '',
    message: '', // 문의 내용 항목 추가
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = '이름을 입력해 주세요.';
    if (!formData.organization) newErrors.organization = '소속기관을 입력해 주세요.';
    if (!formData.email) {
      newErrors.email = '이메일을 입력해 주세요.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = '유효한 이메일 주소를 입력해 주세요.';
    }
    // 휴대폰 번호 검증
    if (!formData.phone) {
      newErrors.phone = '휴대폰 번호를 입력해 주세요.';
    } else if (!/^(\d{3}-\d{3,4}-\d{4})$/.test(formData.phone)) {
      newErrors.phone = '휴대폰 번호는 형식이 010-1234-5678이어야 합니다.';
    }
    if (!formData.message) newErrors.message = '문의 내용을 입력해 주세요.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setIsSubmitting(true);

    try {
      // 피드백 전송
      await sendFeedback({
        feedback_email: formData.email,
        user_name: formData.name,
        user_company: formData.organization,
        phone_number: formData.phone,
        email_message: formData.message,
      });
      
      // 성공적으로 전송되면 폼 데이터 리셋 및 모달 닫기
      setFormData({
        name: '',
        organization: '',
        email: '',
        phone: '',
        message: '',
      });
      setErrors({}); // 에러 초기화
      onRequestClose();
    } catch (error) {
      console.error('피드백 전송 실패:', error);
      // 오류 처리 로직 추가 가능
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    setErrors({}); // 모달이 닫힐 때 에러 초기화
    onRequestClose(); // 모달 닫기
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal} // 모달 닫힐 때 오류 초기화 함수 호출
      style={customModalStyles}
      ariaHideApp={false}
    >
      <h2 className="text-lg font-semibold mb-4">피드백 보내기</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium">이름</label>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="홍길동"
            value={formData.name}
            onChange={handleChange}
            className="bg-gray-700 text-gray-100 border border-gray-600 rounded-md px-3 py-2 w-full outline-none focus:border-blue-500"
          />
          {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
        </div>
        <div>
          <label htmlFor="organization" className="block text-sm font-medium">소속기관</label>
          <input
            id="organization"
            name="organization"
            type="text"
            placeholder="회사명 또는 기관명"
            value={formData.organization}
            onChange={handleChange}
            className="bg-gray-700 text-gray-100 border border-gray-600 rounded-md px-3 py-2 w-full outline-none focus:border-blue-500"
          />
          {errors.organization && <p className="text-red-500 text-xs mt-1">{errors.organization}</p>}
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium">이메일</label>
          <input
            id="email"
            name="email"
            type="email"
            placeholder="example@example.com"
            value={formData.email}
            onChange={handleChange}
            className="bg-gray-700 text-gray-100 border border-gray-600 rounded-md px-3 py-2 w-full outline-none focus:border-blue-500"
          />
          {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
        </div>
        <div>
          <label htmlFor="phone" className="block text-sm font-medium">휴대폰 번호</label>
          <input
            id="phone"
            name="phone"
            type="tel"
            placeholder="010-1234-5678"
            value={formData.phone}
            onChange={handleChange}
            className="bg-gray-700 text-gray-100 border border-gray-600 rounded-md px-3 py-2 w-full outline-none focus:border-blue-500"
          />
          {errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone}</p>}
        </div>
        <div>
          <label htmlFor="message" className="block text-sm font-medium">문의 내용</label>
          <textarea
            id="message"
            name="message"
            placeholder="문의 내용을 입력해 주세요."
            value={formData.message}
            onChange={handleChange}
            className="bg-gray-700 text-gray-100 border border-gray-600 rounded-md px-3 py-2 w-full h-32 resize-none outline-none focus:border-blue-500"
          />
          {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isSubmitting}
            className="bg-blue-600 text-white border border-blue-700 rounded-md px-4 py-2 shadow-md hover:bg-blue-700 disabled:bg-gray-600"
          >
            {isSubmitting ? '전송 중...' : '보내기'}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default FeedbackModal;
