import React, { useEffect, useState } from 'react';
import useStore from '../lib/store/useStore'; // zustand 스토어 임포트
import { getAvg } from '../service/risk.service';

function CeoRiskAvg() {
  // zustand 스토어에서 상태 가져오기
  const { com_name, selectDate } = useStore(state => ({
    com_name: state.com_name,
    selectDate: state.selectDate,
  }));

  // 컴포넌트 내에서 상태 관리하기 위한 useState 훅
  const [score, setScore] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchScore = async () => {
      try {
        const fetchedScore = await getAvg(com_name, selectDate);
       
         // 숫자 또는 문자열 확인 후 처리
         const scoreValue = typeof fetchedScore === 'number'
         ? fetchedScore
         : parseFloat(fetchedScore); // 문자열이면 숫자로 변환
      

       setScore(scoreValue.toFixed(2)); // 소수점 두 번째 자리까지 설정
       console.log('Avg점수:', scoreValue.toFixed(2)); // 콘솔에 점수 출력
      } catch (error) {
        console.error('점수 가져오기 오류:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchScore();
  }, [com_name, selectDate]);

  if (loading) {
    return <div className='text-4xl font-medium text-green-500 text-center pt-14'>Loading...</div>; // 로딩 중 표시
  }

  return (
    <div className="items-center justify-center max-h-40">
      <p className='mx-auto text-base font-medium pt-2 p-3 tracking-tighter truncate'>
        Average score for the last week
      </p>
      <div className='text-4xl custom:text-6xl font-medium text-green-500 text-center pt-3'>
        {score !== null && !isNaN(score) ? score : '0.00'}
      </div>
    </div>
  );
}

export default CeoRiskAvg;
