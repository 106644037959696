// src/components/CompanySelector.js
import React from 'react';
import useStore from '../lib/store/useStore'; // 상대 경로로 스토어 임포트

const CompanySelector = () => {
  // 스토어에서 상태와 액션을 가져옵니다
  const { com_name, setCom_Name } = useStore(state => ({
    com_name: state.com_name,
    setCom_Name: state.setCom_Name,
  }));

  // 선택된 값이 변경될 때 호출되는 함수
  const handleChange = (event) => {
    setCom_Name(event.target.value); 
    console.log('Selected company:', com_name); 
  };

  return (
    <div className="bg-gray-900 rounded-md h-10 border-gray-700 flex items-center justify-center px-2">
          <span className="mr-0 border border-gray-600 rounded-md px-2 py-1 text-blue-500 whitespace-nowrap shadow-lg bg-gradient-to-r from-gray-700 to-gray-900">회사 이름</span>
        <div className="relative">
        <select
          className="bg-gray-800 text-gray-100 border border-gray-600 rounded-md px-2 py-1 outline-none focus:border-blue-500 appearance-none pr-8"
          value={com_name} // 현재 스토어의 name 값을 선택된 값으로 설정
          onChange={handleChange} // 값이 변경될 때 호출
        >
          <option value="SK">SK</option>
          <option value="삼성전자">삼성전자</option>
          <option value="현대자동차">현대자동차</option>
          <option value="LG">LG</option>
          <option value="네이버">네이버</option>
          <option value="카카오">카카오</option>
          <option value="포스코">포스코</option>
          <option value="한진칼">한진칼</option>
          <option value="하이브">하이브</option>
          <option value="SPC">SPC</option>
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CompanySelector;
