import React, { useEffect, useState, useRef } from 'react';
import { TagCloud } from 'react-tagcloud';
import useStore from '../lib/store/useStore';
import { getKeywordCloudData } from '../service/risk.service';
import { Tooltip } from 'react-tooltip';

const KeywordCloud = () => {
  const { com_name, selectDate } = useStore((state) => ({
    com_name: state.com_name,
    selectDate: state.selectDate,
  }));

  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const fetchKeywordData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getKeywordCloudData(com_name, selectDate);
        if (response && Array.isArray(response)) {
          const tagsWithKeys = response.map((tag, index) => ({
            ...tag,
            key: index,
          }));
          setTags(tagsWithKeys);
        }
      } catch (error) {
        setError('Failed to fetch keyword cloud data');
      } finally {
        setLoading(false);
      }
    };

    if (com_name && selectDate) {
      fetchKeywordData();
    }
  }, [com_name, selectDate]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const minSize = Math.max(containerWidth / 45, 13);
  const maxSize = Math.min(containerWidth / 10, 43);

  const levelColors = {
    1: '#FF6347', // 레벨 1: 빨간색
    2: '#4682B4', // 레벨 2: 파란색
    3: '#32CD32', // 레벨 3: 녹색
    4: '#FFD700', // 레벨 4: 노란색
  };

  const defaultColor = '#FFFFFF'; // 레벨이 없는 경우 기본 색상

  return (
    <>
      <p className="mx-auto text-base font-medium pt-2 p-3 -tb-2">Stock Box</p>
      <div
        ref={containerRef}
        className="relative bg-gray-800 w-full h-auto mx-auto p-2 text-center"
      >
        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && !error && (
          <>
            <TagCloud
              minSize={minSize}
              maxSize={maxSize}
              tags={tags}
              className="Keyword_Cloud"
              renderer={(tag, size, color) => (
                <span
                  key={tag.key}
                  style={{
                    fontSize: `${size}px`,
                    color: levelColors[tag.level] || defaultColor, // 레벨이 없을 경우 기본 색상 적용
                    margin: '1px',
                    padding: '1px',
                    cursor: 'pointer',
                    display: 'inline-block',
                  }}
                  data-tooltip-id={`tooltip-${tag.key}`}
                  data-tooltip-content={`${tag.value} : ${tag.count}`}
                >
                  {tag.value}
                </span>
              )}
            />
            {tags.map((tag) => (
              <Tooltip
                key={`tooltip-${tag.key}`}
                id={`tooltip-${tag.key}`}
                place="top"
                type="dark"
                effect="solid"
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default KeywordCloud;
