import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, Filler } from 'chart.js';
import useStore from '../lib/store/useStore'; // Zustand 스토어 임포트
import { getChartData } from '../service/risk.service'; // 서비스 함수 임포트

// Chart.js의 모듈을 등록합니다.
ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, Filler);

const CeoRiskChart = () => {
  const { com_name, selectDate, dateRange } = useStore(state => ({
    com_name: state.com_name,
    selectDate: state.selectDate,
    dateRange: state.dateRange,
  }));

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [cmpCompany, setCmpCompany] = useState('');

  const fetchData = async () => {
    try {
      // 메인 회사 데이터 불러오기
      const response = await getChartData(com_name, selectDate, dateRange);

      if (!response || response.length === 0) {
        throw new Error('No data available for the selected company and date.');
      }

      const sortedResponse = response.sort((a, b) => new Date(a.date) - new Date(b.date));

      // 일간, 주간, 월간에 따라 라벨을 다르게 생성
      const labels = generateLabels(sortedResponse, selectDate, dateRange);

      const data = labels.map(label => {
        const item = sortedResponse.find(item => {
          // 일간일 때는 시간 비교, 주간/월간일 때는 날짜 비교
          if (dateRange === 'daily') {
            // item.date에서 시간과 분을 추출하여 label과 비교 ("HH:mm" 부분)
            const itemTime = item.date.split(' ')[1]; // "2024-09-10 00:00"에서 "00:00" 추출
            return itemTime === label; // "00:00"과 비교
          } else {
            return item.date === label; // 주간/월간일 때 날짜 비교
          }
        });
        return item && item.cumulative_score != null ? parseFloat(item.cumulative_score.toFixed(2)) : null;
      });

      // 비교 회사 데이터 불러오기
      let cmpData = [];

      if (cmpCompany) {
        const cmpResponse = await getChartData(cmpCompany, selectDate, dateRange);

        if (cmpResponse && cmpResponse.length > 0) {
          const sortedCmpResponse = cmpResponse.sort((a, b) => new Date(a.date) - new Date(b.date));

          cmpData = labels.map(label => {
            const item = sortedCmpResponse.find(item => {
              if (dateRange === 'daily') {
                const itemTime = item.date.split(' ')[1]; // 비교 회사의 시간과 분 부분 추출
                return itemTime === label; // "HH:mm"과 비교
              } else {
                return item.date === label; // 주간/월간일 때 날짜 비교
              }
            });
            return item && item.cumulative_score != null ? parseFloat(item.cumulative_score.toFixed(2)) : null;
          });
        }
      }

      // 차트 데이터 설정
      setChartData({
        labels: labels, // 일간은 시간, 주간/월간은 날짜
        datasets: [
          {
            label: `${com_name} Sentimental Score`,
            data: data,
            borderColor: 'rgba(72, 187, 120, 1)',
            backgroundColor: 'rgba(55, 150, 100, 0.5)',
            fill: false,
            spanGaps: true, // 데이터가 없는 구간도 선이 이어지도록 처리
            tension: 0.5, // 곡선형 그래프를 위한 설정
          },
          cmpCompany && {
            label: `${cmpCompany} Sentimental Score`,
            data: cmpData,
            borderColor: 'rgba(255, 99, 132, 1)', // 빨간색
            backgroundColor: 'rgba(255, 99, 132, 0.5)', // 빨간색 배경
            fill: false,
            spanGaps: true, // 데이터가 없는 구간도 선이 이어지도록 처리
            tension: 0.5,
            
          }
        ].filter(Boolean) // cmpCompany가 없을 경우 두 번째 데이터셋을 제거
      });

    } catch (error) {
      console.log('데이터 패칭 오류:', error);
      setChartData({
        labels: [],
        datasets: []
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [com_name, selectDate, cmpCompany, dateRange]);

  // 일간, 주간, 월간에 맞는 라벨 생성 함수
  const generateLabels = (response, date, range) => {
    if (range === 'daily') {
      // 00:00부터 23:00까지의 라벨 생성
      return Array.from({ length: 24 }, (_, i) => `${String(i).padStart(2, '0')}:00`);
    } else {
      // 주간/월간은 서버에서 받은 date 값 사용
      return response.map(item => item.date);
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // 부모 컨테이너에 맞게 차트 크기 조절
    scales: {
      x: {
        title: {
          display: true,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
      y: {
        title: {
          display: true,
        },
        ticks: {
          stepSize: 10,
          max: 100,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'center',
        display: true,
      },
      tooltip: {
        backgroundColor: '#000000',
        titleColor: '#ffffff',
        bodyColor: '#ffffff',
        borderColor: '#ffffff',
        borderWidth: 1,
        callbacks: {
          title: function (tooltipItems) {
            const label = tooltipItems[0].label;
            if (dateRange === 'daily') {
              return `Time: ${label}`; // 일간일 때 시간 표시
            } else {
              return `Date: ${label}`; // 주간/월간일 때 날짜 표시
            }
          },
          label: function (tooltipItem) {
            return `Score: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  return (
    <>
      <div className="flex items-center justify-between mx-auto pl-3 pt-1 pr-3">
        <p className='text-base font-medium'>Sentimental Score</p>
        <select
          className="bg-gray-800 text-gray-100 border border-gray-600 rounded-md px-2 py-1 outline-none focus:border-blue-500 w-auto text-center"
          value={cmpCompany}
          onChange={(e) => setCmpCompany(e.target.value)}
        >
          <option value="">선택</option>
          <option value="SK">SK</option>
          <option value="삼성전자">삼성전자</option>
          <option value="현대자동차">현대자동차</option>
          <option value="LG">LG</option>
          <option value="네이버">네이버</option>
          <option value="카카오">카카오</option>
          <option value="포스코">포스코</option>
          <option value="한진칼">한진칼</option>
          <option value="하이브">하이브</option>
          <option value="SPC">SPC</option>
        </select>
      </div>
      <div className="relative w-full h-80 mx-auto">
        <Line data={chartData} options={options} />
      </div>
    </>
  );
}

export default CeoRiskChart;
