import React, { useState, useEffect } from 'react';
import { getSummaryInfo } from '../service/risk.service';  
import useStore from '../lib/store/useStore'; 
import { ClipLoader } from 'react-spinners'; // react-spinners의 ClipLoader 임포트

function CeoSummary() {
    const [filteredSummaries, setFilteredSummaries] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);  

    const { com_name, selectDate, dateRange } = useStore(state => ({
        com_name: state.com_name,
        selectDate: state.selectDate,
        dateRange: state.dateRange,
    }));

    useEffect(() => {
        let isMounted = true; // 컴포넌트가 마운트된 상태를 추적하기 위한 플래그
    
        const fetchSummaries = async () => {
            setLoading(true); // 로딩 상태 설정
            try {
                console.log('API 호출 시작');
                const summaries = await getSummaryInfo(com_name, selectDate, dateRange);
                console.log('API 응답 데이터:', summaries);
    
                if (isMounted) {
                    let validSummaries = [];
    
                    if (Array.isArray(summaries)) {
                        // 배열이면 기존 방식대로 처리
                        validSummaries = summaries
                            .filter(item => typeof item === 'string' && item.trim() !== '')
                            .map(item => item.trim());
                    } else if (typeof summaries === 'string') {
                        // 단일 문자열이면 배열로 변환
                        validSummaries = [summaries.trim()];
                    }
    
                    console.log('유효한 요약:', validSummaries);    
    
                    setFilteredSummaries(validSummaries);
    
                    if (validSummaries.length > 0) {
                        const intervalId = setInterval(() => {
                            setCurrentIndex((prevIndex) => (prevIndex + 1) % validSummaries.length);
                        }, 5000);
                        return () => clearInterval(intervalId);
                    } else {
                        console.log('토큰이 없습니다..');
                        setFilteredSummaries([]);  // 빈 배열 설정
                    }
                }
            } catch (error) {
                console.error('API 호출 실패:', error);
                if (isMounted) setFilteredSummaries([]);  // 에러 발생 시 빈 배열 설정
            } finally {
                if (isMounted) setLoading(false); // 로딩 상태 해제
            }
        };
    
        if (com_name && selectDate) {
            fetchSummaries();
        } else {
            console.log('com_name 또는 selectDate가 유효하지 않습니다');
            setLoading(false);
        }
    
        return () => {
            isMounted = false;
        };
    }, [com_name, selectDate, dateRange]);

    return (
        <div>
            <p className='mx-auto text-base font-medium pt-1 pl-2'>Summarize article</p>  
            <div className="wip-info-container p-3 pl-3 h-auto text-sm custom:text-base font-medium ">
                {loading ? (
                    <div className="flex justify-center items-center">
                        <ClipLoader color="#36D7B7" size={30} />
                    </div>
                ) : filteredSummaries.length > 0 ? (
                    <div className="text-xs custom:text-sm text-green-500 ">
                        <div className="pt-1 line-clamp-2 pl-2">
                            {filteredSummaries[currentIndex]}
                        </div>
                    </div>
                ) : (
                    <p className="text-xs custom:text-sm text-green-500 ">토큰이 없습니다. 추가결제를 해주세요.</p>
                )}
            </div>
        </div>
    );
}

export default CeoSummary;
