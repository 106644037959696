import React, { useState, useEffect } from 'react';
import { getWipInfo } from '../service/risk.service';
import useStore from '../lib/store/useStore'; // Zustand 스토어 임포트

function CeoLlmBox() {
    const [wipInfo, setWipInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가

    const { com_name, selectDate, dateRange } = useStore(state => ({
        com_name: state.com_name,
        selectDate: state.selectDate,
        dateRange: state.dateRange,
    }));

    useEffect(() => {
        const fetchWipInfo = async () => {
            try {
                const data = await getWipInfo(com_name, selectDate, dateRange);
                // wipInfo가 배열인지 확인
                if (Array.isArray(data)) {
                    setWipInfo(data);
                } else {
                    setWipInfo([]); // 배열이 아닌 경우 빈 배열로 설정
                }
            } catch (error) {
                console.error('Error fetching WIP info:', error);
            } finally {
                setIsLoading(false); // 로딩 상태 변경
            }
        };

        fetchWipInfo();
    }, [com_name, selectDate, dateRange]);

    // 텍스트가 너무 길면 잘라서 '...' 추가하는 함수
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    return (
        <div>
            <p className='mx-auto text-base font-medium pt-2 p-3'>LLM Box</p>
            <div className="wip-info-container p-4 h-auto text-sm custom:text-base font-medium">
                {isLoading ? (
                    <p>Loading...</p> // 로딩 중 표시
                ) : wipInfo.length > 0 ? ( // 데이터가 있을 때
                    wipInfo.map((info, index) => (
                        <div key={index} className="info-item mb-2">
                            {/* 최대 길이 50자로 제한하여 표시 */}
                            <p>{truncateText(info, 50)}</p>
                        </div>
                    ))
                ) : ( // 데이터가 없을 때
                    <p>토큰이 없습니다. 추가결제를 해주세요.</p>
                )}
            </div>
        </div>
    );
}

export default CeoLlmBox;
